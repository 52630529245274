/* eslint-disable react/no-array-index-key */

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import pluralize from 'pluralize'
import { find, snakeCase, upperFirst } from 'lodash'
import { Tooltip, Icon, Badge } from 'antd'
import { NavLink } from 'react-router-dom'
import { LinkButton } from 'Traqa/Packages'
import { getTeamUrl } from 'Traqa/Helpers'
import styles from './Component.css'

const List = (props) => {

  const {
    filter,
    notifications,
    users,
    onToggleRead,
    onClickReply,
  } = props

  return (

    <div className={styles.list}>
      
      {notifications.filter(notification => filter === 'UNREAD' ? !notification.read_at : true).map(notification => {
        
        const who = find(users, { id: Number(notification.data.user_id) })
        const title = `${who ? who.first_name : 'Someone'} mentioned you in a comment`
        
        const modelId = notification.data.model_id
        const modelType = notification.data.model_type
        const modelName = pluralize(snakeCase(modelType.replace('App\\Models\\', '')).split('_').map(w => upperFirst(w)).join(' '))
        const modelUrl = getTeamUrl(`${snakeCase(modelName)}/${modelId}`)
        
        return (
        
          <div className={classNames(styles.notification, {[styles.unread]: !notification.read_at})} key={notification.id}>
            
            <div className={styles.iconArea}>
              
              <Badge dot={!notification.read_at}>
                <div className={styles.iconCircle}>
                  <Icon type="message" theme="filled" className={styles.icon} />
                </div>
              </Badge>
              
            </div>
            
            <div className={styles.contentArea}>
              
              <div className={styles.title}>{title}</div>
              
              <div className={styles.subtitle}>
                <NavLink to={modelUrl}>
                  {`${modelName} #${modelId}`}
                </NavLink>
              </div>
              
              <div className={styles.snippet}>
                {'"'}
                {notification.data.content.split(' ').map((word, i) => {
                    
                  if (/^@\[.*?\]\(.*?\)$/.test(word)) {
                    
                    const username = word.match(/^@\[(.*?)\]\(.*?\)$/)[1]
                    const userId = word.match(/^@\[.*?\]\((.*?)\)$/)[1]
                    const user = find(users, { id: Number(userId) })
                    
                    return (
                      <Tooltip key={i} title={user ? user.name : username}>
                        {`@${username}`}
                      </Tooltip>
                    )
                    
                  }
                  
                  return word
                    
                }).reduce((prev, curr) => [prev, ' ', curr])}
                {'"'}
              </div>
              
              <div className={styles.actions}>
                
                {!notification.read_at && (
                  <LinkButton type="primary" onClick={() => onToggleRead(notification)} className={styles.action} icon="check">
                    Read
                  </LinkButton>
                )}
                
                {!!notification.read_at && (
                  <LinkButton type="default" onClick={() => onToggleRead(notification)} className={styles.action} icon="close">
                    Unread
                  </LinkButton>
                )}
                
                {false && ( // temp disabled until feature ready
                  <LinkButton type="primary" onClick={() => onClickReply(notification)} className={styles.action} icon="enter">
                    Reply
                  </LinkButton>
                )}
                
              </div>
              
            </div>
            
            <div className={styles.right}>
              
              <div className={styles.timestamp}>
                {moment(notification.created_at).fromNow(true)}
              </div>
              
            </div>
            
          </div>
          
        )
        
      })}
      
    </div>
    
  )
}

List.propTypes = {
  filter: PropTypes.string.isRequired,
  notifications: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  onToggleRead: PropTypes.func.isRequired,
  onClickReply: PropTypes.func.isRequired,
}

export default List