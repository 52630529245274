import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import styles from './styles.css'

export default class CustomerSites extends React.PureComponent {

  static propTypes = {
    customer: PropTypes.object,
    onRowClick: PropTypes.func.isRequired,
  }

  static defaultProps = {
    customer: null,
  }

  render () {

    const {
      customer,
      onRowClick,
    } = this.props

    if (!customer) return null
    
    return (

      <Table
        dataSource={customer.customer_sites}
        rowKey='id'
        pagination={false}
        scroll={{ y: 240 }}
        className={styles.table}
        onRow={record => ({
          onClick: () => onRowClick(record.id),
        })}
      >

        <Table.Column
          key='name'
          title='Sites'
          render={(text, record) => record.name}
        />

      </Table>

    )

  }

}
