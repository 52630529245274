import { connect } from 'react-redux'
import Component from './Component'

import onRowClick from './Handlers/onRowClick'

const mapStateToProps = (state) => ({
  customer: state.modals.Customer.customer,
})

const actionCreators = {
  onRowClick,
}

export default connect(mapStateToProps, actionCreators)(Component)
