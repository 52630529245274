import { createAction, handleActions } from 'redux-actions'

const setControls = createAction('SCREENS/DEMAND/REPORT/SET_CONTROLS')
const setFilter = createAction('SCREENS/DEMAND/REPORT/SET_FILTER')
const resetControls = createAction('SCREENS/DEMAND/REPORT/RESET_CONTROLS')

const defaultState = {
  search: '',
  productCategoryId: null,
  stockLocationId: null,
  stockHolding: null,
  periodType: 'week',
  periodOffset: -5,
  periodCount: 11,
  hideZeros: false,
  expectedOnly: false,
  backOrders: false,
  outersOnly: true,
  aggregate: true,
  runRateSource: 'demand',
  runRateSetting: 'manual',
}

const reducer = handleActions(
  {
    [setControls]: (state, { payload }) => payload,
    [setFilter]: (state, { payload }) => ({ ...state, [payload.key]: payload.value }),
    [resetControls]: () => defaultState,
  },
  defaultState,
)

export {
  setControls,
  setFilter,
  resetControls,
  reducer,
}
