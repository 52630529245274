import { createSelector } from 'reselect'

const selectControls = state => state.screens.Demand.Report.controls

export default createSelector(
  selectControls,
  (controls) => {

    if (controls.search) return true
    if (controls.productCategoryId) return true
    if (controls.stockLocationId) return true
    if (controls.stockHolding) return true
    if (controls.hideZeros) return true
    if (controls.expectedOnly) return true
    if (controls.backOrders) return true
    if (controls.outersOnly) return true
    if (controls.aggregate) return true
    if (controls.runRateSource) return true
    if (controls.runRateSetting) return true

    return false

  }
)
