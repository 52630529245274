import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Field, FormSpy } from 'react-final-form'
import { Form, Row, Col, Select, Tag, InputNumber, Checkbox, Popover, Button } from 'antd'
import styles from './Item.css'

import FulfillmentProgress from './Components/FulfillmentProgress'

const Item = (props) => {

  const {
    field,
    index,
    guid,
    isNew,
    isSelected,
    products,
    suppliers,
    onToggle,
  } = props

  const truncate = (input) => input.length > 60 ? `${input.substring(0, 60)}...` : input
  
  return (

    <FormSpy subscription={{ submitting: true }}>

      {({ submitting }) => (

        <div>

          <Row gutter={8}>

            <Col span={1}>

              <Row type="flex" align="middle" className={classNames(styles.checkboxWrapper, {[styles.firstRow]: index === 0})}>

                <Col span={12}>

                  <Checkbox
                    checked={isSelected}
                    onChange={() => onToggle(guid)}
                    disabled={submitting}
                  />
                
                </Col>

                <Col span={12}>

                  <span className={styles.itemNumber}>{index + 1}</span>
                
                </Col>
              
              </Row>

            </Col>

            <Col span={7}>

              <Field name={`${field}.product_id`} allowNull>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Product' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      dropdownMatchSelectWidth={false}
                    >

                      <Select.OptGroup label="Products">
                        {products.filter(product => !product.archived_at).map(product => (
                          <Select.Option key={product.id} value={product.id}>
                            {truncate(`${product.code} - ${product.name}`)}
                            <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                          </Select.Option>
                        ))}
                      </Select.OptGroup>

                      {!isNew && (

                        <Select.OptGroup label="Archived">
                          {products.filter(product => product.archived_at).map(product => (
                            <Select.Option key={product.id} value={product.id} style={{ color: '#999' }}>
                              {truncate(`${product.code} - ${product.name}`)}
                              <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                            </Select.Option>
                          ))}
                        </Select.OptGroup>

                      )}

                    </Select>
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={6}>

              <Field name={`${field}.supplier_id`} allowNull>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Supplier' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      dropdownMatchSelectWidth={false}
                    >

                      <Select.OptGroup label="Suppliers">
                        {suppliers.filter(supplier => !supplier.archived_at).map(supplier => (
                          <Select.Option key={supplier.id} value={supplier.id}>
                            {supplier.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>

                      {!isNew && (

                        <Select.OptGroup label="Archived">
                          {suppliers.filter(supplier => !!supplier.archived_at).map(supplier => (
                            <Select.Option key={supplier.id} value={supplier.id} style={{ color: '#999' }}>
                              {supplier.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>

                      )}

                    </Select>
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={1}>
              <Popover
                trigger={['hover']}
                content={(
                  <div style={{ width: 140 }}>
                    <Field name={`${field}.supplier_cost`}>
                      {({ input, meta }) => (
                        <Form.Item
                          label='Supplier Cost'
                          colon={false}
                          validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                        >
                          <InputNumber
                            {...input}
                            disabled={submitting}
                            min={0}
                            step={1}
                            precision={2}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      )}
                    </Field>
                    <Field name={`${field}.freight_cost`}>
                      {({ input, meta }) => (
                        <Form.Item
                          label='Freight Cost'
                          colon={false}
                          validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                        >
                          <InputNumber
                            {...input}
                            disabled={submitting}
                            min={0}
                            step={1}
                            precision={2}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      )}
                    </Field>
                    <Field name={`${field}.duty_cost`}>
                      {({ input, meta }) => (
                        <Form.Item
                          label='Duty Cost'
                          colon={false}
                          validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                        >
                          <InputNumber
                            {...input}
                            disabled={submitting}
                            min={0}
                            step={1}
                            precision={2}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      )}
                    </Field>
                  </div>
                )}
              >
                <Button
                  icon='pound'
                  style={{ marginTop: index === 0 ? 29 : 0, width: '100%' }}
                />
              </Popover>
            </Col>

            <Col span={2}>

              <Field name={`${field}.landed_cost`}>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Landed Cost' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <InputNumber
                      {...input}
                      disabled={submitting}
                      min={0}
                      step={1}
                      precision={2}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={2}>

              <Field name={`${field}.quantity`}>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Qty' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <InputNumber
                      {...input}
                      disabled={submitting}
                      min={0}
                      step={1}
                      precision={0}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={4}>

              <div className={classNames(styles.progressWrapper, {[styles.firstRow]: index === 0})}>

                <FulfillmentProgress field={field} />
              
              </div>

            </Col>

          </Row>

        </div>
    
      )}

    </FormSpy>

  )
}

Item.propTypes = {
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  guid: PropTypes.string.isRequired,
  productId: PropTypes.any,
  isNew: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
  onToggle: PropTypes.func.isRequired,
}

Item.defaultProps = {
  productId: null,
}

export default Item