import chance from 'chance'
import { getDemandReport } from '../../../../../Api/reports'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setInteractionId } from '../State/interaction'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const { controls } = getState().screens.Demand.Report

  const params = {
    search: controls.search,
    product_category_id: controls.productCategoryId,
    stock_location_id: controls.stockLocationId,
    stock_holding: controls.periodType === 'week' ? controls.stockHolding : undefined,
    period_type: controls.periodType,
    period_offset: controls.periodOffset,
    period_count: controls.periodCount,
    expected_only: controls.expectedOnly ? 1 : 0,
    back_orders: controls.backOrders ? 1 : 0,
    outers_only: controls.outersOnly ? 1 : 0,
    aggregate: controls.aggregate ? 1 : 0,
    run_rate_source: controls.runRateSource,
    run_rate_setting: controls.runRateSetting !== 'expected' ? controls.runRateSetting : undefined,
  }

  // Prevent slower API responses from overriding faster ones

  const guid = chance().guid()

  dispatch(setInteractionId(guid))

  params.interaction_id = guid

  dispatch(startFetching())

  try {

    const { data } = await getDemandReport({ params })

    if (data.interaction_id === getState().screens.Demand.Report.interaction.id) {

      dispatch(setData(data))

    }

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }
  
}
