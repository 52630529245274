import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Divider, Button } from 'antd'

import Form from './Components/Form'
import CustomerSites from './Components/CustomerSites'
import CustomerSite from '../CustomerSite'

export default class CustomerModal extends React.PureComponent {

  static propTypes = {
    customerId: PropTypes.number,
    isSubmitting: PropTypes.bool.isRequired,
    customerSiteModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onClickAddCustomerSite: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCloseCustomerSiteModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    customerId: null,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      customerId,
      isSubmitting,
      customerSiteModal,
      onClickAddCustomerSite,
      onSubmit,
      onCloseCustomerSiteModal,
      onClose,
    } = this.props

    const footer = []

    if (customerId) {
      footer.push(
        <Button key="add" type="link" style={{ float: 'left' }} loading={isSubmitting} onClick={() => onClickAddCustomerSite(customerId)}>
          Add Site
        </Button>
      )
    }

    footer.push(
      <Button key="cancel" disabled={isSubmitting} onClick={() => onClose()}>
        Cancel
      </Button>,
      <Button key="save" type="primary" loading={isSubmitting} onClick={onSubmit}>
        {customerId ? "Update" : "Create"}
      </Button>,
    )

    return (
        
      <Modal
        visible  
        title={customerId ? "Customer" : "New Customer"}
        width={500}
        footer={footer}
        onCancel={() => onClose()}
      >
        
        <Form />

        {customerId && (

          <React.Fragment>

            <Divider />

            <CustomerSites />

          </React.Fragment>

        )}

        {customerSiteModal.show && (
          <CustomerSite
            {...customerSiteModal}
            onClose={onCloseCustomerSiteModal}
          />
        )}

      </Modal>
      
    )
  }
}