import modalProps from '../modalProps'
import formService from '../Components/Form/formService'
import { findManualRunRateIfExists } from '../../../Api/manualRunRates'
import { setManualRunRate } from '../State/manualRunRate'
import { handleException } from 'Traqa/Packages'
import { defer } from 'lodash'

export default () => async (dispatch) => {

  const { stockLocationId, product, period } = modalProps()

  const params = {
    stock_location_id: stockLocationId,
    product_id: product.id,
    start_date_time: period.start_date_time,
    end_date_time: period.end_date_time,
  }

  try {

    const response = await findManualRunRateIfExists({ params })

    const manualRunRate = response.data.data
    
    const initialValues = {
      year_duplicate: true,
    }
    
    if (manualRunRate) {

      dispatch(setManualRunRate(manualRunRate))

      initialValues.quantity = manualRunRate.quantity || null
      initialValues.notes = manualRunRate.notes
      
    }

    defer(() => formService().initialize(initialValues))

  } catch (error) {

    handleException(error)

  }

}
