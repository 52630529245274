import React from 'react'
import PropTypes from 'prop-types'
import { Table, Tooltip, Button, Icon, Badge } from 'antd'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import numeral from 'numeral'
import classNames from 'classnames'
import { getTeamUrl, isSupplyChain } from 'Traqa/Helpers'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    goodsInRecords: PropTypes.array.isRequired,
    tableRows: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    onClickAddProductToGoodsInRecord: PropTypes.func.isRequired,
    onClickEditGoodsInRecord: PropTypes.func.isRequired,
    onClickGoodsInRecordComments: PropTypes.func.isRequired,
    onClickDuplicateGoodsInRecord: PropTypes.func.isRequired,
    onClickConvertIntoPurchaseOrder: PropTypes.func.isRequired,
    onClickDeleteGoodsInRecord: PropTypes.func.isRequired,
    onClickEditableCell: PropTypes.func.isRequired,
  }

  render() {

    const {
      goodsInRecords,
      tableRows,
      isFetching,
      onClickAddProductToGoodsInRecord,
      onClickEditGoodsInRecord,
      onClickGoodsInRecordComments,
      onClickDuplicateGoodsInRecord,
      onClickConvertIntoPurchaseOrder,
      onClickDeleteGoodsInRecord,
      onClickEditableCell,
    } = this.props

    const goodsInRecordActions = (goodsInRecord) => (
      <div className={styles.goodsInRecordActions}>
        {isSupplyChain && (
          <Tooltip title='Add Product' placement='bottom'>
            <Button
              shape='circle'
              icon='plus'
              size='small'
              onClick={() => onClickAddProductToGoodsInRecord(goodsInRecord)}
            />
          </Tooltip>
        )}
        {isSupplyChain && (
          <Tooltip title='Edit Details' placement='bottom'>
            <Button
              shape='circle'
              icon='edit'
              size='small'
              className={styles.goodsInRecordActionButton}
              onClick={() => onClickEditGoodsInRecord(goodsInRecord.id)}
            />
          </Tooltip>
        )}
        <Badge count={goodsInRecord.comment_count}>
          <Tooltip title='Comment' placement='bottom'>
            <Button
              shape='circle'
              icon='message'
              size='small'
              className={styles.goodsInRecordActionButton}
              onClick={() => onClickGoodsInRecordComments(goodsInRecord.id)}
            />
          </Tooltip>
        </Badge>
        {isSupplyChain && (
          <Tooltip title='Duplicate' placement='bottom'>
            <Button
              shape='circle'
              icon='copy'
              size='small'
              className={styles.goodsInRecordActionButton}
              onClick={() => onClickDuplicateGoodsInRecord(goodsInRecord.id)}
            />
          </Tooltip>
        )}
        {isSupplyChain && (
          <Tooltip title='Convert Into PO' placement='bottom'>
            <Button
              shape='circle'
              icon='file-protect'
              size='small'
              className={styles.goodsInRecordActionButton}
              onClick={() => onClickConvertIntoPurchaseOrder(goodsInRecord.id)}
            />
          </Tooltip>
        )}
        {isSupplyChain && (
          <Tooltip title='Delete' placement='bottom'>
            <Button
              shape='circle'
              icon='delete'
              size='small'
              className={styles.goodsInRecordActionButton}
              onClick={() => onClickDeleteGoodsInRecord(goodsInRecord.id)}
            />
          </Tooltip>
        )}
      </div>
    )

    const isEditableCell = type => type === 'Product' && isSupplyChain

    const onTheWater = {
      0: { text: 'Scheduled', icon: 'clock-circle', color: 'orange' },
      1: { text: 'On the Water', icon: 'arrow-right', color: '#1890ff' },
    }

    return (

      <Table
        dataSource={tableRows}
        rowKey='key'
        bordered
        loading={isFetching}
        pagination={false}
        style={{ marginTop: '-1px' }}
      >

        <Table.Column
          key='product'
          title='Product'
          onCell={record => ({
            className: classNames(styles.cell, {
              [styles.totals]: record.type === 'Total',
            }),
          })}
          render={(text, record) => record.type === 'Product' && <NavLink to={getTeamUrl(`products/${record.product.id}`)}>{record.title}</NavLink>}
        />

        {goodsInRecords.map((goodsInRecord, index) => (

          <Table.ColumnGroup
            key={goodsInRecord.id}
            title={(
              <div>
                <div style={{ fontSize: '15px' }}>{goodsInRecord.invoice_no}</div>
                <div style={{ fontWeight: 300 }}>
                  <Icon type='shop' />
                  {` ${goodsInRecord.supplier.name}`}
                </div>
                <div style={{ fontWeight: 300 }}>
                  <Icon type='environment' />
                  {` ${goodsInRecord.stock_location.name}`}
                </div>
                <div>{moment(goodsInRecord.date_time).format('Do MMM YYYY')}</div>
                {!!goodsInRecord.commercial_value_usd && (
                  <div style={{ fontWeight: 300 }}>
                    <Icon type='dollar' />
                    {` ${numeral(goodsInRecord.commercial_value_usd).format('0,0.00')}`}
                  </div>
                )}
                <div>
                  <Icon type={onTheWater[goodsInRecord.on_the_water].icon} style={{ color: onTheWater[goodsInRecord.on_the_water].color }} />
                  {` ${onTheWater[goodsInRecord.on_the_water].text}`}
                </div>
                {goodsInRecordActions(goodsInRecord)}
              </div>
            )}
            className={classNames(styles.goodsInRecordHeader)}
          >

            <Table.Column
              key={`quantity_per_pallet-${goodsInRecord.id}`}
              title='Per Pallet'
              className={classNames(styles.numerical)}
              onCell={record => ({
                className: classNames(styles.cell, styles.numerical, {
                  [styles.editable]: isEditableCell(record.type),
                  [styles.totals]: record.type === 'Total',
                }),
                onClick: () => {
                  if (isEditableCell(record.type)) {
                    onClickEditableCell(goodsInRecord, record.product, record.values[index])
                  }
                },
              })}
              render={(text, record) => record.type === 'Product' && record.values[index].quantity_per_pallet !== null ? numeral(record.values[index].quantity_per_pallet).format('0,0') : null}
            />

            <Table.Column
              key={`pallet_quantity-${goodsInRecord.id}`}
              title='Pallets'
              className={classNames(styles.numerical)}
              onCell={record => ({
                className: classNames(styles.cell, styles.numerical, {
                  [styles.editable]: isEditableCell(record.type),
                  [styles.totals]: record.type === 'Total',
                }),
                onClick: () => {
                  if (isEditableCell(record.type)) {
                    onClickEditableCell(goodsInRecord, record.product, record.values[index])
                  } 
                },
              })}
              render={(text, record) => {
                if (record.type === 'Product' && record.values[index].pallet_quantity !== null) return numeral(record.values[index].pallet_quantity).format('0,0')
                if (record.type === 'Total' && record.values[index].total_pallets !== null) return numeral(record.values[index].total_pallets).format('0,0')
                return null
              }}
            />

            <Table.Column
              key={`quantity-${goodsInRecord.id}`}
              title='Quantity'
              className={classNames(styles.numerical)}
              onCell={record => ({
                className: classNames(styles.cell, styles.numerical, styles.quantity, {
                  [styles.editable]: isEditableCell(record.type),
                  [styles.totals]: record.type === 'Total',
                }),
                onClick: () => {
                  if (isEditableCell(record.type)) {
                    onClickEditableCell(goodsInRecord, record.product, record.values[index])
                  }
                },
              })}
              render={(text, record) => record.type === 'Product' && record.values[index].quantity !== null ? numeral(record.values[index].quantity).format('0,0') : null}
            />

          </Table.ColumnGroup>

        ))}

      </Table>

    )

  }

}
