import modalProps from '../../../modalProps'
import { createManualRunRate, updateManualRunRate } from '../../../../../Api/manualRunRates'
import { handleException } from 'Traqa/Packages'

export default (values) => async (dispatch, getState) => {

  const data = {
    quantity: values.quantity,
    notes: values.notes || null,
    year_duplicate: values.year_duplicate || undefined,
  }

  try {

    const { manualRunRate } = getState().modals.ManualRunRate

    if (manualRunRate) {

      await updateManualRunRate({ id: manualRunRate.id, data })

    } else {

      const { stockLocationId, product, periodType, period } = modalProps()

      data.stock_location_id = stockLocationId
      data.product_id = product.id
      data.period_type = periodType
      data.start_date_time = period.start_date_time
      data.end_date_time = period.end_date_time

      await createManualRunRate({ data })

    }

    modalProps().onClose(true)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
