import { getTeamUrl } from 'Traqa/Helpers'

export default () => (dispatch, getState) => {

  const { controls } = getState().screens.Demand.Report

  const params = {
    search: controls.search,
    product_category_id: controls.productCategoryId || undefined,
    stock_location_id: controls.stockLocationId || undefined,
    period_type: controls.periodType,
    period_offset: controls.periodOffset,
    period_count: controls.periodCount,
    expected_only: controls.expectedOnly ? 1 : 0,
    back_orders: controls.backOrders ? 1 : 0,
    outers_only: controls.outersOnly ? 1 : 0,
    aggregate: controls.aggregate ? 1 : 0,
    run_rate_source: controls.runRateSource,
    run_rate_setting: controls.runRateSetting !== 'expected' ? controls.runRateSetting : undefined,
  }

  if (controls.periodType === 'week') {
    params.stock_holding = controls.stockHolding || undefined
  }

  let path = `reports/demand/export?`

  Object.keys(params).forEach(key => {
    if (params[key] === undefined) return
    path = `${path}&${key}=${params[key]}`
  })

  window.location = getTeamUrl(path, 'exports')

}
