import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Table, Modal, Tag, Tooltip } from 'antd'
import { has } from 'lodash'
import classNames from 'classnames'
import numeral from 'numeral'
import { getTeamUrl, isSupplyChain } from 'Traqa/Helpers'
import styles from './styles.css'

const noStockLocationFilter = () => {
  Modal.info({
    title: 'Location Filter Not Set',
    content: (
      <div>
        <p>To adjust manual run rates, you must filter by location first.</p>
        <p>This is because run rates are location specific.</p>
        <p>If no location filter is applied, the quantity shown in the cell will be the total run rate across all locations.</p>
        <p><b>Make sure you reset the location filter once the run rates have been set. Otherwise, the closing figures will be location specific.</b></p>
      </div>
    ),
  })
}

const isClickableCell = (tableRowType, tableRowGroup, periodIndex, currentType, runRateSetting) => {
  if (tableRowType !== 'Product') return false
  if (tableRowGroup === 'run_rate') {
    if (periodIndex > 0 || currentType === 'current-future') {
      if (runRateSetting !== 'expected' && runRateSetting !== 'manual') return false
    }
  }
  if (tableRowGroup === 'close') return false
  return true
}

const isManualRunRateCell = (tableRowType, tableRowGroup, periodIndex, currentType, runRateSetting, backOrder) => {
  if (tableRowType !== 'Product') return false
  if (tableRowGroup !== 'run_rate') return false
  if (periodIndex < 0 || currentType === 'current-past') return false
  if (runRateSetting !== 'manual') return false
  if (backOrder) return false
  return true
}

const isTransactionsCell = (tableRowType, tableRowGroup, periodIndex, currentType, runRateSetting, backOrder) => {
  if (tableRowType !== 'Product') return false
  if (tableRowGroup === 'close') return false
  if (tableRowGroup === 'run_rate') {
    if (periodIndex > 0 || currentType === 'current-future') {
      if (runRateSetting !== 'expected' && !backOrder) return false
    }
  }
  return true
}

const isTrafficLight = (periodType, tableRowGroup, period) => {
  if (periodType !== 'week') return false
  if (tableRowGroup !== 'close') return false
  if (!has(period, 'runRateCountdown')) return false
  return true
}

const isTrafficLightGreen = (periodType, tableRowGroup, period) => {
  if (!isTrafficLight(periodType, tableRowGroup, period)) return false
  return period.runRateCountdown === null
}

const isTrafficLightOrange = (periodType, tableRowGroup, period) => {
  if (!isTrafficLight(periodType, tableRowGroup, period)) return false
  return period.runRateCountdown >= 10 && period.runRateCountdown < 16
}

const isTrafficLightRed = (periodType, tableRowGroup, period) => {
  if (!isTrafficLight(periodType, tableRowGroup, period)) return false
  return period.runRateCountdown !== null && period.runRateCountdown < 10
}

export default class extends React.PureComponent {

  static propTypes = {
    periods: PropTypes.array.isRequired,
    tableRows: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    stockLocationId: PropTypes.number,
    periodType: PropTypes.string.isRequired,
    runRateSetting: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onClickManualRunRateCell: PropTypes.func.isRequired,
    onClickTransactionsCell: PropTypes.func.isRequired,
  }

  static defaultProps = {
    stockLocationId: null,
  }

  render() {

    const {
      periods,
      tableRows,
      isFetching,
      stockLocationId,
      periodType,
      runRateSetting,
      onClickManualRunRateCell,
      onClickTransactionsCell,
    } = this.props

    const onCellClick = (record, period, currentType = undefined, demand = undefined, backOrder = undefined) => {
      if (isManualRunRateCell(record.type, record.group, period.index, currentType, runRateSetting, backOrder)) {
        if (!isSupplyChain()) return null
        if (!stockLocationId) {
          noStockLocationFilter()
          return null
        }
        onClickManualRunRateCell(record.product, period)
      } else if (isTransactionsCell(record.type, record.group, period.index, currentType, runRateSetting, backOrder)) {
        onClickTransactionsCell(record.product, record.group, period, currentType, demand, backOrder)
      }
      return null
    }
    
    return (

      <Table
        dataSource={tableRows}
        rowKey='key'
        bordered
        loading={isFetching}
        pagination={false}
        style={{ marginTop: '-1px' }}
      >

        <Table.Column
          key='product'
          title='Product'
          width={350}
          onCell={(record) => ({
            className: record.type === 'Group' ? styles.groupCell : styles.productCell,
          })}
          render={(text, record) => record.type === 'Group' ? record.title : (
            <NavLink to={getTeamUrl(`products/${record.product.id}`)}>
              <div>
                {`${record.product.code} - ${record.product.name}`}
                <Tag color='volcano' style={{ marginLeft: 5 }}>{record.product.form}</Tag>
              </div>
            </NavLink>
          )}
        />

        {periods.map((period, index) => {

          if (period.index === 0) return (

            <Table.ColumnGroup
              key={period.index}
              title={(
                <div>
                  <div>{period.display}</div>
                  {period.week_of_year && <div style={{ fontSize: 12 }}>{period.week_of_year}</div>}
                </div>
              )}
              className={classNames(styles.periodHeaderCell, styles.currentPeriodHeaderCell)}
            >

              <Table.Column
                key='current-past'
                colSpan={0}
                onCell={(record) => ({
                  className: classNames(styles.periodCell, {
                    [styles.groupCell]: record.type === 'Group',
                    [styles.currentPeriodCell]: record.type === 'Product',
                    [styles.clickableCell]: isClickableCell(record.type, record.group, period.index, 'current-past', runRateSetting),
                    [styles.zeroValue]: (() => {
                      if (record.type !== 'Product') return false
                      return record.values[index].past.value === 0
                    })(),
                  }),
                  onClick: () => onCellClick(record, period, 'current-past'),
                })}
                render={(text, record) => {
                  if (record.type !== 'Product') return null
                  const value = numeral(record.values[index].past.value).format('0,0')
                  const { demand } = record.values[index].past
                  if (record.group === 'run_rate' && demand !== undefined) {
                    return (
                      <React.Fragment>
                        <span
                          onClick={e => {
                            onCellClick(record, period, 'current-past', true)
                            e.stopPropagation()
                          }}
                          style={{ color: demand > 0 ? 'orange' : '#CCC', marginRight: 5 }}
                        >
                          {numeral(demand).format('0,0')}
                        </span>
                        <span>{value}</span>
                      </React.Fragment>
                    )
                  }
                  return numeral(record.values[index].past.value).format('0,0')
                }}
              />

              <Table.Column
                key='current-future'
                colSpan={0}
                onCell={(record) => ({
                  className: classNames(styles.periodCell, {
                    [styles.groupCell]: record.type === 'Group',
                    [styles.currentPeriodCell]: record.type === 'Product',
                    [styles.clickableCell]: isClickableCell(record.type, record.group, period.index, 'current-future', runRateSetting),
                    [styles.zeroValue]: (() => {
                      if (record.type !== 'Product') return false
                      return record.values[index].future.value === 0
                    })(),
                    [styles.trafficLightGreen]: record.type === 'Product' && isTrafficLightGreen(periodType, record.group, record.values[index].future),
                    [styles.trafficLightOrange]: record.type === 'Product' && isTrafficLightOrange(periodType, record.group, record.values[index].future),
                    [styles.trafficLightRed]: record.type === 'Product' && isTrafficLightRed(periodType, record.group, record.values[index].future),
                  }),
                  onClick: () => onCellClick(record, period, 'current-future'),
                })}
                render={(text, record) => {
                  if (record.type !== 'Product') return null
                  const value = numeral(record.values[index].future.value).format('0,0')
                  if (record.group === 'run_rate') return (
                    <React.Fragment>
                      <Tag
                        color='red'
                        onClick={e => {
                          onCellClick(record, period, 'current-future', undefined, true)
                          e.stopPropagation()
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {numeral(record.values[index].future.back_order).format('0,0')}
                      </Tag>
                      <span>{value}</span>
                    </React.Fragment>
                  )
                  if (record.group !== 'close' || periodType !== 'week' || record.product.stock_holding === null) return value
                  return <Tooltip title={`${record.product.stock_holding} weeks`} placement='right' defaultVisible>{value}</Tooltip>
                }}
              />

            </Table.ColumnGroup>

          )

          return (

            <Table.Column
              key={period.index}
              title={(
                <div>
                  <div>{period.display}</div>
                  {period.week_of_year && <div style={{ fontSize: 12 }}>{period.week_of_year}</div>}
                </div>
              )}
              onHeaderCell={() => ({
                className: classNames(styles.periodHeaderCell, {
                  [styles.pastPeriodHeaderCell]: period.index < 0,
                  [styles.futurePeriodHeaderCell]: period.index > 0,
                }),
              })}
              onCell={(record) => ({
                className: classNames(styles.periodCell, {
                  [styles.groupCell]: record.type === 'Group',
                  [styles.pastPeriodCell]: record.type === 'Product' && period.index < 0,
                  [styles.futurePeriodCell]: record.type === 'Product' && period.index > 0,
                  [styles.clickableCell]: isClickableCell(record.type, record.group, period.index, undefined, runRateSetting),
                  [styles.zeroValue]: (() => {
                    if (record.type !== 'Product') return false
                    return record.values[index].value === 0
                  })(),
                  [styles.trafficLightGreen]: record.type === 'Product' && isTrafficLightGreen(periodType, record.group, record.values[index]),
                  [styles.trafficLightOrange]: record.type === 'Product' && isTrafficLightOrange(periodType, record.group, record.values[index]),
                  [styles.trafficLightRed]: record.type === 'Product' && isTrafficLightRed(periodType, record.group, record.values[index]),
                }),
                onClick: () => onCellClick(record, period),
              })}
              render={(text, record) => {
                if (record.type !== 'Product') return null
                const value = numeral(record.values[index].value).format('0,0')
                const { demand } = record.values[index]
                if (record.group === 'run_rate' && demand !== undefined) {
                  return (
                    <React.Fragment>
                      <span
                        onClick={e => {
                          onCellClick(record, period, undefined, true)
                          e.stopPropagation()
                        }}
                        style={{ color: demand > 0 ? 'orange' : '#CCC', marginRight: 5 }}
                      >
                        {numeral(demand).format('0,0')}
                      </span>
                      <span>{value}</span>
                    </React.Fragment>
                  )
                }
                return value
              }}
            />

          )

        })}

      </Table>
    )

  }

}
